import React, { useState } from 'react';
import { Box, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CompaniesList from './CompaniesList';
import CustomSnackbar from '../../common/Snackbar';
import { useDispatch, useSelector } from '../../hooks/redux';
import {
  selectCompany,
  selectCompanyDetails,
  selectCompanyError,
  selectCompanyFlags,
} from '../../store/selectors/companies';
import { resetCompanyError, resetCompanyFlags } from '../../store/slices/companies';
import CompanyDetails from './CompanyDetails';
import CompanyGatewayDetails from './CompanyGatewayDetails';
import AlertDialog from '../../common/AlertDialog';
import useAccessControl from '../../hooks/useAccessControl';
import { accessKeys, routes } from '../../constants';

/* ------- Styles ------- */
const CompaniesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '64px 48px 0 48px',
  backgroundColor: '#FBFBFB',
  width: '100%',
});

/* ------- Components ------- */
const Companies = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { AccessControl, userAccess } = useAccessControl();

  const [showCompanyDetails, setShowCompanyDetails] = useState<boolean>(false);
  const [showGatewayDetails, setShowGatewayDetails] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<string | undefined>(undefined);

  const details = useSelector(selectCompanyDetails);
  const company = useSelector(selectCompany);
  const { created, updated, deleted, isError } = useSelector(selectCompanyFlags);
  const errorData = useSelector(selectCompanyError);

  const showNotification = created || updated || deleted;
  const getEventType = () => {
    switch (true) {
      case created:
        return 'add';
      case updated:
        return 'edit';
      case deleted:
        return 'delete';
      default:
        return 'default';
    }
  };

  const handleNotificationClose = () => {
    dispatch(resetCompanyFlags());
    dispatch(resetCompanyError());
  };

  const handleOpenDetails = (id: string) => {
    setCompanyId(id);
    setShowCompanyDetails(true);
  };

  const handleAddNewGateway = (id: string) => {
    setCompanyId(id);
    setShowGatewayDetails(true);
  };

  const handleCloseDetails = () => {
    setCompanyId(undefined);
    setShowCompanyDetails(false);
  };

  const handleOpenAddNew = () => {
    setCompanyId(undefined);
    setShowCompanyDetails(true);
  };

  return (
    <AccessControl accessKeys={[accessKeys.VIEW_COMPANIES_ALLOWED, accessKeys.VIEW_GATEWAYS_ALLOWED]} redirectUrl={routes.UNAUTHORIZED}>
      <CompaniesContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3 style={{ fontSize: '24px', margin: 0 }}>{t('companies:title')}</h3>
          {userAccess.create_company_allowed ? (
            <Button
              variant='contained'
              sx={{ color: '#fff', fontSize: '15px', maxHeight: '42px', minWidth: '192px' }}
              onClick={handleOpenAddNew}
            >
              {t('companies:addNew')}
            </Button>
          ) : null}
        </div>

        <CompaniesList onOpenDetails={handleOpenDetails} onAddNewGateway={handleAddNewGateway} />

        <CompanyDetails companyId={companyId} open={showCompanyDetails} onClose={handleCloseDetails} />

        <CompanyGatewayDetails
          gatewayId={undefined}
          companyId={companyId}
          show={showGatewayDetails}
          onClose={() => setShowGatewayDetails(false)}
        />

        <CustomSnackbar
          severity='success'
          open={showNotification}
          onClose={handleNotificationClose}
          message={`"${details?.name || company?.name}" ${t(`companies:dialog:${getEventType()}:notificationSuccess`)}`}
        />

        <AlertDialog
          open={isError && !!errorData}
          onConfirm={() => handleNotificationClose()}
          dialogTitle={t('companies:deleteCompany:error')}
          dialogText={errorData?.['CompanyId'][0]}
        />
      </CompaniesContainer>
    </AccessControl>
  );
};

export default Companies;
